import mock from '../mock'
import { paginateArray } from '../utils'

/* eslint-disable */
const data = {
    leads: [
        {
            "id": "44284331",
            "lead": "Residencial Ipe Amarelo Etapa 1",
            "contacts": [
                {
                    "name": "AVIDA CONSTRUTORA E INCORPORADORA S",
                    "id": "09.001.183/0001-34",
                    "obs": "Construtora"
                },
                {
                    "name": "222AVIDA CONSTRUTORA E INCORPORADORA S",
                    "id": "09.001.183/0001-35",
                    "obs": "Tomador"
                },
            ],
            "dateCreate": "17/08/2020",
            "update": "17/08/2020",
            "sellers": [{
                "name": "Vendedor 1",
                "id": "1"
            },
                {
                    "name": "Vendedor 2",
                    "id": "2"
            }],
            "obs": {
                "CNPJ TOMADOR": "",
                "TOMADOR": "",
                "SGE": "",
                "PRODUTO": "SEM PJ",
                "OR": "SEM PJ",
                "%DAOBRA": "47,36",
                "INÍCIO OBRA": "17/08/2020",
                "TÉRM OBRA ORIGINAL": "17/08/2023",
                "TÉRM OBRA ATUAL": "17/07/2023",
                "TÉRM OBRA EFETIVO": "",
                "SITUAÇÃO": "ATIVO EM CONSTRUÇÃO",
                "PRAZO OBRA": "36",
                "VGV": "8.579.600,00",
                "FINANCIADO": "0,00",
                "MUNICÍPIO": "CUIABA",
                "UF": "MT",
                "UH": "62",
                "AREA": "SUMEP",
                "SR": "4196",
                "CUSTO OBRA": "6.652.343,77",
                "AVALIAÇÃO TERRENO": "589.000,00",
                "PV": "4314",
                "ATENDIMENTO DA SUSPENSIVA": "07/10/2020",
                "TÉRMINO DA SUSPENSIVA": "",
                "Comercial": "",
                "UF BRAVO": "",
                "Situação": "",
                "Corretora HubSpot": "",
                "update": "2022-08-29T18:37:32.941Z"
            }

        },
        {
            "id": "44284333",
            "lead": "Residencial Ipe vermelho Etapa 2",
            "contacts": [
                {
                    "name": "AVIDA CONSTRUTORA E INCORPORADORA S",
                    "id": "09.001.183/0001-34",
                    "obs": "Construtora"
                },
                {
                    "name": "222AVIDA CONSTRUTORA E INCORPORADORA S",
                    "id": "09.001.183/0001-35",
                    "obs": "Tomador"
                },
            ],
            "dateCreate": "19/08/2020",
            "update": "20/08/2021",
            "sellers": [{
                "name": "Vendedor 1",
                "id": "1"
            },
                {
                    "name": "Vendedor 2",
                    "id": "2"
            }],
            "obs": {
                "CNPJ TOMADOR": "",
                "TOMADOR": "",
                "SGE": "",
                "PRODUTO": "SEM PJ",
                "OR": "SEM PJ",
                "%DAOBRA": "80,36",
                "INÍCIO OBRA": "17/08/2020",
                "TÉRM OBRA ORIGINAL": "17/08/2023",
                "TÉRM OBRA ATUAL": "17/07/2023",
                "TÉRM OBRA EFETIVO": "",
                "SITUAÇÃO": "ATIVO EM CONSTRUÇÃO",
                "PRAZO OBRA": "36",
                "VGV": "8.579.600,00",
                "FINANCIADO": "0,00",
                "MUNICÍPIO": "CUIABA",
                "UF": "MT",
                "UH": "62",
                "AREA": "SUMEP",
                "SR": "4196",
                "CUSTO OBRA": "6.652.343,77",
                "AVALIAÇÃO TERRENO": "589.000,00",
                "PV": "4314",
                "ATENDIMENTO DA SUSPENSIVA": "07/10/2020",
                "TÉRMINO DA SUSPENSIVA": "",
                "Comercial": "",
                "UF BRAVO": "",
                "Situação": "",
                "Corretora HubSpot": "",
                "update": "2022-08-29T18:37:32.941Z"
            }

        },
    ]
}

// GET ALL DATA
mock.onGet('/api/bravo/list/all-data').reply(config => {
    const { q = '', perPage = 10, page = 1, status = null } = config
    let filteredData = data.leads
    return [
        200,
        {
            allData: data.leads,
            leads: paginateArray(filteredData, perPage, page),
            total: filteredData.length
        }
    ]
})

// POST: Add new lead
mock.onPost('/apps/bravo/add-user').reply(config => {
    // Get event from post data
    const user = JSON.parse(config.data)

    const { length } = data.users
    let lastIndex = 0
    if (length) {
        lastIndex = data.users[length - 1].id
    }
    user.id = lastIndex + 1

    data.users.unshift(user)

    return [201, { user }]
})

// GET Updated DATA
mock.onGet('/api/bravo/list/data').reply(config => {
    const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config

    /* eslint-disable  */
    const queryLowered = q.toLowerCase()
    const filteredData = data.users.filter(
        user =>
            (user.username.toLowerCase().includes(queryLowered) || user.fullName.toLowerCase().includes(queryLowered)) &&
            user.role === (role || user.role) &&
            user.currentPlan === (currentPlan || user.currentPlan) &&
            user.status === (status || user.status)
    )
    /* eslint-enable  */

    return [
        200,
        {
            users: paginateArray(filteredData, perPage, page),
            total: filteredData.length
        }
    ]
})

// GET lead
mock.onGet('/api/bravo/user').reply(config => {
    const { apf } = config
    const user = data.users.find(i => i.apf === apf)
    return [200, { lead }]
})

// DELETE: Deletes User
mock.onDelete('/apps/bravo/delete').reply(config => {
    // Get user id from URL
    let leadId = config.apf

    // Convert Id to number
    leadId = Number(leadId)

    const userIndex = data.leads.findIndex(t => t.apf === leadId)
    data.leads.splice(userIndex, 1)

    return [200]
})
