// ** Initial State
const initialState = {
  documents: {},
  quotations: null,
  brokersInfo: null,
  referrer: null,
  modalRegistReferrer: false,
  insurers: []
}

const RcpmReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEND_DATA':
      return { ...state, status: action.leads }
    case 'GET_QUOTATIONS':
      return { ...state, quotations: action.data }
    case 'FETCH_DOCUMENTS':
      return { ...state, documents: action.documents }
    case 'GET_BROKERS_INFO':
      return { ...state, brokersInfo: action.brokers }  
    case 'SET_REFERRER':
      return { ...state, referrer: action.payload }  
    case 'MODAL_REGIST_REFERRER':
      return { ...state, modalRegistReferrer: action.payload }  
    case 'GET_INSURERS':
      return { ...state, insurers: action.insurers }  
    default:
      return state
  }
}
export default RcpmReducer
