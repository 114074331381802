import styled, { keyframes } from "styled-components"

const SplashScreen = () => {
  return (
    <Div>
        <LoadingContainer>
            <Loading />
            <AppIcon src={require(process.env.REACT_APP_ICON_PATH).default} alt="App logo" className="bravoIcon"/>
        </LoadingContainer>
    </Div>
  )
}

// Animation
const isRotate = keyframes`
    to { transform: rotate(1turn) }
`

// Components
const Div = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`
const LoadingContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Loading = styled.div`
    border: 8px solid #e5e5e5;
    border-top-color: #1e73c7;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    position: absolute;
    animation: ${isRotate} 1s infinite;
`
const AppIcon = styled.img`
    max-width: 80px;
    width: 60px;
    height: auto;
    position: absolute;
`

export default SplashScreen