import axios from 'axios'
import mock from '../mock'

//usar axios para fazer um get em https://mocki.io/v1/24bb1594-0f68-44b1-8ccd-7c576d6df614

// const data = axios.get('https://mocki.io/v1/24bb1594-0f68-44b1-8ccd-7c576d6df614').then(res => {
//   console.log("axios", res.data)
//   return res.data
// }).catch(err => {
//   console.log(err)
// })


// ------------------------------------------------
// GET: Return Tasks
// ------------------------------------------------
// mock.onGet('/apps/bravo/leads').reply(async config => {

//   console.log("FakeDB", config)
//   const { q = '', filter, tag, sortBy: sortByParam = 'latest' } = config.params
//   const pageNumber = config?.params?.pageNumber === undefined ? 1 : config?.params?.pageNumber

//   const type = config?.params?.type === undefined ? 'all' : config?.params?.type

//   const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}lead/?searchParam=${q}e&type=${type}&pageNumber=${pageNumber}`)
//   const data = response.data

//   // eslint-disable-next-line object-curly-newline
  
//   /* eslint-enable */
//   // ------------------------------------------------
//   // Get Sort by and Sort Direction
//   // ------------------------------------------------
//   let sortDesc = true

//   const sortBy = (() => {
//     if (sortByParam === 'title-asc') {
//       sortDesc = false
//       return 'title'
//     }
//     if (sortByParam === 'title-desc') return 'title'
//     if (sortByParam === 'assignee') {
//       sortDesc = false
//       return 'assignee'
//     }
//     if (sortByParam === 'due-date') {
//       sortDesc = false
//       return 'createdAt'
//     }
//     return 'id'
//   })()

//   // ------------------------------------------------
//   // Filtering
//   // ------------------------------------------------
//   const queryLowered = q.toLowerCase()

//   const hasFilter = task => {
//     if (filter === 'important') return task.isImportant && !task.isDeleted
//     if (filter === 'completed') return task.isCompleted && !task.isDeleted
//     if (filter === 'deleted') return task.isDeleted
    
//     return !task.isDeleted
//   }
//   /* eslint-disable */
//   const filteredData = data.leads.filter(lead => {
//     return lead.name.toLowerCase().includes(queryLowered) 
//   })

//   /* eslint-enable  */

//   // ------------------------------------------------
//   // Perform sorting
//   // ------------------------------------------------
//   const sortTasks = key => (a, b) => {
//     let fieldA
//     let fieldB

//     // If sorting is by dueDate => Convert data to date
//     if (key === 'dueDate') {
//       fieldA = new Date(a[key])
//       fieldB = new Date(b[key])
//       // eslint-disable-next-line brace-style
//     }

//     // If sorting is by assignee => Use `fullName` of assignee
//     else if (key === 'assignee') {
//       fieldA = a.assignee ? a.assignee.fullName : null
//       fieldB = b.assignee ? b.assignee.fullName : null
//     } else {
//       fieldA = a[key]
//       fieldB = b[key]
//     }

//     let comparison = 0

//     if (fieldA === fieldB) {
//       comparison = 0
//     } else if (fieldA === null) {
//       comparison = 1
//     } else if (fieldB === null) {
//       comparison = -1
//     } else if (fieldA > fieldB) {
//       comparison = 1
//     } else if (fieldA < fieldB) {
//       comparison = -1
//     }

//     return comparison
//   }

//   // Sort Data
//   const sortedData = filteredData.sort(sortTasks(sortBy))
//   console.log("🚀 ~ file: leads.js ~ line 217 ~ mock.onGet ~ sortedData", sortedData)
  
//   if (sortDesc) sortedData.reverse()
//   return [200, sortedData]
// })


// ------------------------------------------------
// POST: Add new task
// ------------------------------------------------
mock.onPost('/apps/todo/add-tasks').reply(config => {
  // Get event from post data
  const { task } = JSON.parse(config.data)

  const { length } = data.leads
  let lastIndex = 0
  if (length) {
    lastIndex = data.leads[length - 1].id
  }
  task.id = lastIndex + 1

  data.leads.push(task)

  return [201, { task }]
})

// ------------------------------------------------
// POST: Update Task
// ------------------------------------------------
mock.onPost('/apps/todo/update-task').reply(config => {
  const taskData = JSON.parse(config.data).task

  // Convert Id to number
  taskData.id = Number(taskData.id)

  const task = data.leads.find(e => e.id === Number(taskData.id))
  Object.assign(task, taskData)

  return [200, { task }]
})

// ------------------------------------------------
// DELETE: Remove Task
// ------------------------------------------------
mock.onDelete('/apps/todo/delete-task').reply(config => {
  // Get task id from URL
  let taskId = config.taskId

  // Convert Id to number
  taskId = Number(taskId)

  const task = data.leads.find(t => t.id === taskId)
  Object.assign(task, { isDeleted: true })
  return [200]
})
